import React, { useState, useCallback } from "react"
import {graphql, useStaticQuery} from "gatsby"
import Layout from "../components/layout"
import "../styles/pages/page.scss"
import SEO from "../components/seo";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

const PageTemplate = () => {
  const { allWordpressPage } = useStaticQuery(
    graphql`
      query {
        allWordpressPage(filter: {slug: {eq: "portfolio"}}) {
          edges {
            node {
              title
              content
              acf {
                gallery {
                  localFile {
                    url
                    childImageSharp {
                      sizes(maxWidth: 10, maxHeight: 10) {
                        presentationWidth
                        presentationHeight
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  let pageHasGallery = false;
  const photos = [];
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const page = allWordpressPage.edges[0].node;

  if (page.acf.gallery !== null) {
    pageHasGallery = true;

    for (let i = 0; i < page.acf.gallery.length; i++) {
      photos.push({'src': page.acf.gallery[i].localFile.url,
                  'width' : page.acf.gallery[i].localFile.childImageSharp.sizes.presentationWidth,
                  'height': page.acf.gallery[i].localFile.childImageSharp.sizes.presentationHeight});
    }
  }

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
    document.body.parentElement.classList.add('gallery-open');
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
    document.body.parentElement.classList.remove('gallery-open');
  };

  return (
    <Layout>
      <SEO title={page.title} />
      <div className="page">
        <div className="container">
          <h1 className="title">{page.title}</h1>
        </div>
        <div className="fluid-container">
          {/*<div className="page__content text-style" dangerouslySetInnerHTML={{__html: currentPage.content}}>*/}
          {/*</div>*/}

          {pageHasGallery ?
            <div>
              <Gallery photos={photos} onClick={openLightbox} />
              <ModalGateway>
                {viewerIsOpen ? (
                  <Modal onClose={closeLightbox}>
                    <Carousel
                      currentIndex={currentImage}
                      views={photos.map(x => ({
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title
                      }))}
                    />
                  </Modal>
                ) : null}
              </ModalGateway>
            </div>
          :
          ''
          }
        </div>
      </div>
    </Layout>
  )
}
export default PageTemplate
